import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ImportShippingRequest from './pages/ImportShippingRequest';
import ExportShippingRequest from './pages/ExportShippingRequest';
import NonAssetSale from './pages/NonAssetSaleForm';
import SuccessPage from './pages/SuccessPage';
import './styles/main.scss';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <AuthorizeRoute path='/import-shipping-request' component={ImportShippingRequest} />
                <AuthorizeRoute path='/export-shipping-request' component={ExportShippingRequest} />
                <AuthorizeRoute path='/non-asset-sale-form' component={NonAssetSale} />
                <AuthorizeRoute path='/thank-you' component={SuccessPage} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }
}
