import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <h1>Kaust Procurement</h1>
                <p>1.0.0a</p>
            </div>
        );
    }
}
