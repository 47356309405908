import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Formik, Form } from "formik";
import { string, object, number, array } from "yup";
import AddProduct from "../AddProduct/AddProduct";
import productTypesList from "../../../../../mocks/product-types-list.json";
import conditionList from "../../../../../mocks/condition-list.json";

const ProductModal = ({
  isModalOpen,
  handleToggleModal,
  handleProductDetails,
  values,
}) => {
  const [isValidForm, setIsValidForm] = useState(false);
  const [isFormCleared, setIsFormCleared] = useState(false);

  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader toggle={handleToggleModal}>Add New Record</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={values}
          validationSchema={object({
            description: string().required("Product Description is required."),
            productTypeDescription: string()
              .oneOf(
                productTypesList.map((condition) => condition.name),
                "Product Type is required."
              )
              .required("Product Type is required."),
            quantity: number()
              .typeError("Quantity is invalid.")
              .required("Quantity is required."),
            prodCondDescription: string()
              .oneOf(
                conditionList.map((condition) => condition.name),
                "Condition is required."
              )
              .required("Condition is required."),
            ProductPictures: array()
              .min(1, "Required field.")
              .max(5, "Too many files.")
              .required("Required field."),
          })}
          onSubmit={(productValues, actions) => {
            if (!isValidForm) {
              actions.setSubmitting(false);
              return;
            }

            handleToggleModal();
            handleProductDetails(productValues);
          }}
        >
          {(props) => (
            <Form>
              <AddProduct
                values={props.values}
                setFieldValue={props.setFieldValue}
                setIsValidForm={setIsValidForm}
                conditionList={conditionList}
                productTypesList={productTypesList}
                isFormCleared={isFormCleared}
                meta={props.meta}
                touched={props.touched}
                error={props.errors}
              />
              <div className="modal-footer-container text-center">
                <Button color="primary" type="submit">
                  Insert
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() => {
                    props.handleReset();
                    setIsFormCleared(true);
                  }}
                >
                  Clear
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ProductModal;
