import React from "react";

const Heading = ({ children, level, className }) => {
  const validHeadingLevels = [1, 2, 3, 4, 5, 6];
  const Heading = validHeadingLevels.includes(parseInt(level))
    ? `h${level}`
    : `div`;

  return <Heading className={className ? className : ""}>{children}</Heading>;
};

Heading.defaultProps = {
  level: 1,
};

export default Heading;
