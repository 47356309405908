import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Field, useField } from "formik";
import { enUS } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import FormikInput from "../../../formik/FormikInput/FormikInput";
import FormikSelect from "../../../formik/FormikSelect/FormikSelect";
import FormikTextarea from "../../../formik/FormikTextarea/FormikTextarea";
import Label from "../../../molecules/Label/Label";
import Required from "../../../atoms/Required/Required";
import Input from "../../../atoms/Input/Input";

const AdditionalInfo = ({
  values,
  setFieldValue,
  countryList,
  shipmentTypesList,
  forwardersList,
  statusList,
  meta,
  touched,
  error,
  ...props
}) => {
  const [field] = useField(props);

  const [receivedDate, setReceivedDate] = useState();
  const [scheduledDate, setScheduledDate] = useState();
  const [POD, setPOD] = useState();
  const [newPOD, setNewPOD] = useState();

  const handleSelectedCountry = (e, field) => {
    let selectedCountryId = "";

    countryList.filter((country) => {
      if (country.name === e.target.value) {
        return (selectedCountryId = country.id);
      }
    });

    setFieldValue("countryOfOrigin", e.target.value, false);
    setFieldValue("countryOfOriginId", selectedCountryId, false);
    field.onChange(e);
  };

  const handleDateFormat = (selectedDate, field) => {
    if (!selectedDate) {
      setFieldValue(field, "");
      return;
    }

    let month = parseInt(("0" + selectedDate.getMonth()).slice(-2)) + 1;

    if (month.toString().length == 1) {
      month = "0" + month;
    }

    setFieldValue(
      field,
      month +
        "/" +
        ("0" + selectedDate.getDate()).slice(-2) +
        "/" +
        selectedDate.getFullYear()
    );
  };

    const handleAdditionalAttachment = (e, field) => {      
        var check = Array.from(field.value["Attachment"]);
        
        if (e.target.files[0] == null) {
            setFieldValue("Attachment", check.filter(el => el.name.startsWith("consignee") || el.name.startsWith("shipper") ), false);
            return;
        }

        if (check.length == 0) {
            var newFileS = new File([e.target.files[0]], `additional_${e.target.files[0].name}`);
            setFieldValue("Attachment", [newFileS], false);
        }
        else {
            if (check.some(el => el.name.startsWith("additional"))) {
                var newFileA = new File([e.target.files[0]], `additional_${e.target.files[0].name}`);
                if (check.some(el => el.name.startsWith("consignee") || el.name.startsWith("shipper"))) {
                    var otherFiles = check.filter(el => el.name.startsWith("consignee") || el.name.startsWith("shipper"));
                    otherFiles.push(newFileA);

                    setFieldValue("Attachment", otherFiles, false);
                }
                else {
                    setFieldValue("Attachment", [newFileA], false);
                }
            }
            else {
                var newFileB = new File([e.target.files[0]], `additional_${e.target.files[0].name}`);
                check.push(newFileB);
                setFieldValue("Attachment", check, false);
            }
        }
        field.onChange(e);
  };

  return (
    <fieldset>
      <Row>
        <Col md="6">
          <label>
            Country of origin
            <Required />
            <span className="small">
              (if sending SAMPLES from KAUST, choose Saudi Arabia. Otherwise,
              choose country of manufacture)
            </span>
          </label>
          <FormikSelect
            optionsList={countryList}
            name="countryOfOrigin"
            onChange={(e) => {
              handleSelectedCountry(e, field);
            }}
          />
        </Col>
      </Row>

      <Row className="align-items-end">
        <Col md="4">
          <div className="form-group">
            <Label label="Received from Site Service" />
            <Field name="receivedFromSiteService">
              {({ field }) => (
                <>
                  <DatePicker
                    name="receivedFromSiteService"
                    date={scheduledDate}
                    onDateChange={(selectedDate) => {
                      setScheduledDate(selectedDate);
                      handleDateFormat(selectedDate, "receivedFromSiteService");
                    }}
                    format="MM/dd/yyyy"
                    locale={enUS}
                    {...field}
                    {...props}
                  >
                    {({ inputProps }) => (
                      <input className="form-control" {...inputProps} />
                    )}
                  </DatePicker>
                </>
              )}
            </Field>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <Label label="Scheduled Dispatch/Collection" />
            <Field name="scheduledForDispatch">
              {({ field }) => (
                <>
                  <DatePicker
                    name="scheduledForDispatch"
                    date={receivedDate}
                    onDateChange={(selectedDate) => {
                      setReceivedDate(selectedDate);
                      handleDateFormat(selectedDate, "scheduledForDispatch");
                    }}
                    format="MM/dd/yyyy"
                    locale={enUS}
                    {...field}
                    {...props}
                  >
                    {({ inputProps }) => (
                      <input className="form-control" {...inputProps} />
                    )}
                  </DatePicker>
                </>
              )}
            </Field>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <Label label="POD" />
            <Field name="pod">
              {({ field }) => (
                <>
                  <DatePicker
                    name="pod"
                    date={POD}
                    onDateChange={(selectedDate) => {
                      setPOD(selectedDate);
                      handleDateFormat(selectedDate, "pod");
                    }}
                    format="MM/dd/yyyy"
                    locale={enUS}
                    {...field}
                    {...props}
                  >
                    {({ inputProps }) => (
                      <input className="form-control" {...inputProps} />
                    )}
                  </DatePicker>
                </>
              )}
            </Field>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormikInput
            name="NumberOfPackages"
            type="number"
            label="Number of Packages"
          />
        </Col>
        {values.pod && (
          <Col md="6">
            <Label label="POD file" />
                      <Input type="file" name="file" className="mb-4" onInputChange={(e) => handleAdditionalAttachment(e, field)}/>
          </Col>
        )}
      </Row>
      <Row>
        <Col md="4">
          <FormikSelect
            optionsList={shipmentTypesList}
            label="Shipment Type"
            name="shipmentType"
            onChange={(e) => {
              setFieldValue("shipmentType", e.target.value, false);
              field.onChange(e);
            }}
          />
        </Col>
        <Col md="4">
          <FormikSelect
            optionsList={forwardersList}
            label="Forwarder"
            name="forwarder"
            onChange={(e) => {
              setFieldValue("forwarder", e.target.value, false);
              field.onChange(e);
            }}
          />
        </Col>
        <Col md="4">
          <FormikSelect
            optionsList={statusList}
            label="Status"
            name="status"
            onChange={(e) => {
              setFieldValue("status", e.target.value, false);
              field.onChange(e);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Label
            label="AWB Number"
            isRequired={values.receivedFromSiteService ? true : false}
          />
          <FormikInput name="awbNumber" maxLength="50" />
        </Col>
        <Col md="6">
          <FormikInput label="PO Number" name="poNumber" maxLength="50" />
        </Col>
      </Row>
      {values.status === "RTO" && (
        <Row>
          <Col md="6">
            <FormikInput name="newAWB" label="New AWB" />
          </Col>
          <Col md="6">
            <Label label="New POD" />
            <Field name="newPOD">
              {({ field }) => (
                <>
                  <DatePicker
                    name="newPOD"
                    date={newPOD}
                    onDateChange={(selectedDate) => {
                      setNewPOD(selectedDate);
                      handleDateFormat(selectedDate, "newPOD");
                    }}
                    format="MM/dd/yyyy"
                    locale={enUS}
                    {...field}
                    {...props}
                  >
                                      {({ inputProps }) => (
                                          <input className="form-control" {...inputProps}  />
                    )}
                  </DatePicker>
                </>
              )}
            </Field>
          </Col>
        </Row>
      )}
      <Row>
        <Col md="6">
          <FormikTextarea
            isRequired
            label="Additional Remarks"
            maxLength="500"
            name="additionalRemarks"
          />
        </Col>
        <Col md="6">
          <label className="mt-md-4 pt-md-1">
            <Field type="checkbox" name="isComplete" className="mr-2" />
            Mark as Complete
          </label>
        </Col>
      </Row>
    </fieldset>
  );
};

export default AdditionalInfo;
