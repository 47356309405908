import React from "react";
import { Field } from "formik";
import Label from "../../molecules/Label/Label";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";

const FormikInput = ({ label, disabled, maxLength, isRequired, ...props }) => {
  return (
    <div className="form-group">
      <Field name={props.name}>
        {({ field, meta }) => (
          <>
            {label && (
              <Label
                fieldId={props.id || props.name}
                label={label}
                isRequired={isRequired}
              />
            )}
            <input
              id={props.id || props.name}
              maxLength={maxLength}
              disabled={disabled ? disabled : null}
              className="form-control"
              {...field}
              {...props}
            />
            {meta.touched && meta.error ? (
              <ErrorMessage errorMessage={meta.error} />
            ) : null}
          </>
        )}
      </Field>
    </div>
  );
};

FormikInput.defaultProps = {
  type: `text`,
};

export default FormikInput;
