import React from "react";
import { Row, Col } from "reactstrap";
import { useField } from "formik";
import LegendWithUnderline from "../../../molecules/LegendWithUnderline/LegendWithUnderline";
import FormikInput from "../../../formik/FormikInput/FormikInput";
import FormikTextarea from "../../../formik/FormikTextarea/FormikTextarea";
import FormikSelect from "../../../formik/FormikSelect/FormikSelect";

const ShipperDetails = ({ setFieldValue, countryList, ...props }) => {
  const [field] = useField(props);

  const handleSelectedCountry = (e, field) => {
    let selectedCountryId = "";

    countryList.filter((country) => {
      if (country.name === e.target.value) {
        return (selectedCountryId = country.id);
      }
    });

    setFieldValue("shipperCountry", e.target.value, false);
    setFieldValue("shipperCountryId", selectedCountryId, false);
    field.onChange(e);
  };

  return (
    <fieldset className="pb-5">
      <LegendWithUnderline
        legend="Shipper Details"
        className="h2 text-center"
      />
      <FormikInput maxLength="50" isRequired label="Name" name="shipperName" />

      <Row>
        <Col md="6">
          <FormikInput
            maxLength="50"
            label="Title / Position"
            name="shipperPosition"
            type="text"
          />
        </Col>
        <Col>
          <FormikInput
            isRequired
            maxLength="50"
            label="Company or KAUST Department"
            name="shipperCompany"
            type="text"
          />
        </Col>
      </Row>
      <FormikTextarea
        isRequired
        label="Full Address"
        maxLength="500"
        name="shipperfullAddress"
      />
      <Row>
        <Col md="6">
          <FormikSelect
            optionsList={countryList}
            label="Country"
            name="shipperCountry"
            onChange={(e) => {
              handleSelectedCountry(e, field);
            }}
          />
        </Col>
        <Col md="6">
          <FormikInput
            label="City"
            maxLength="100"
            name="shipperCity"
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <FormikInput
            maxLength="50"
            label="Phone #"
            name="shipperPhone"
            type="text"
            isRequired
          />
        </Col>
        <Col>
          <FormikInput
            maxLength="50"
            isRequired
            label="Email"
            name="shipperEmail"
            type="email"
          />
        </Col>
      </Row>
    </fieldset>
  );
};

export default ShipperDetails;
