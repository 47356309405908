import React from "react";
import { Field } from "formik";
import Label from "../../molecules/Label/Label";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";

const FormikSelect = ({ label, isRequired, optionsList, ...props }) => {
  return (
    <Field name={props.name}>
      {({ field, meta }) => (
        <div className="form-group">
          {label && (
            <Label
              fieldId={props.id || props.name}
              label={label}
              isRequired={isRequired}
            />
          )}
          <select
            name={props.name}
            className="form-control"
            {...field}
            {...props}
          >
            <option value="---Select---">---Select---</option>

            {optionsList ? (
              <>
                {optionsList.map((option, index) => (
                  <option value={option.name} key={option.id || index}>
                    {option.name}
                  </option>
                ))}
              </>
            ) : null}
            
          </select>
          {meta.touched && meta.error ? (
            <ErrorMessage errorMessage={meta.error} />
          ) : null}
        </div>
      )}
    </Field>
  );
};

export default FormikSelect;
