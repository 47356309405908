import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Formik, Form } from "formik";
import { string, object, number, bool, array } from "yup";
import AddProduct from "../AddProduct/AddProduct";
import unitsList from "../../../../../mocks/units-list.json";
import shippingConditionList from "../../../../../mocks/shipping-condition.json";

const ProductModal = ({
  isModalOpen,
  handleToggleModal,
  handleProductDetails,
  values,
}) => {
  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader toggle={handleToggleModal}>Add New Record</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={values}
          validationSchema={object({
            Description: string().required("Description is required."),
            UOM: string()
              .oneOf(
                unitsList.map((unit) => unit.name),
                "UOM is required."
              )
              .required("UOM is required."),
            Quantity: number()
              .typeError("Quantity is invalid.")
              .required("Quantity is required."),
            ShippingCondition: string()
              .oneOf(
                shippingConditionList.map((condition) => condition.name),
                "Shipping Condition is required."
              )
              .required("Shipping Condition is required."),
            UnitValue: number()
              .typeError("Unit Value is invalid.")
              .required("Unit Value is required."),
            ProductAttachment: array().when("IsChemical", {
              is: (IsChemical) => IsChemical === true,
              then: array()
                .min(1, "Required field.")
                .max(1, "Please, select only one file."),
              otherwise: array(),
            }),
            IsChemical: bool()
              .typeError("Required field.")
              .required("Required field."),
          })}
          onSubmit={(productValues) => {
            handleToggleModal();
            handleProductDetails(productValues);
          }}
        >
          {(props) => (
            <Form>
              <AddProduct
                values={props.values}
                setFieldValue={props.setFieldValue}
                unitsList={unitsList}
                shippingConditionList={shippingConditionList}
                meta={props.meta}
                touched={props.touched}
                error={props.errors}
              />
              <div className="modal-footer-container text-center">
                <Button color="primary" type="submit">
                  Insert
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() => {
                    props.handleReset();
                  }}
                >
                  Clear
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ProductModal;
