import React, { useState } from "react";
import { Formik, Form } from "formik";
import { string, object, array, number } from "yup";
import { Redirect } from "react-router-dom";
import countryList from "../mocks/country-list.json";
import shipmentTypesList from "../mocks/shipment-types-list.json";
import forwardersList from "../mocks/forwarders-list.json";
import statusList from "../mocks/status-list.json";
import authService from "../components/api-authorization/AuthorizeService";
import Heading from "../components/atoms/Heading/Heading";
import PaymentDetails from "../components/organisms/export/PaymentDetails/PaymentDetails";
import ShipperDetails from "../components/organisms/export/ShipperDetails/ShipperDetails";
import ConsigneeDetails from "../components/organisms/export/ConsigneeDetails/ConsigneeDetails";
import ProductDetails from "../components/organisms/export/ProductDetails/ProductDetails";
import ConditionalWrapper from "../components/atoms/ConditionalWrapper/ConditionalWrapper";
import AdditionalInfo from "../components/organisms/export/AdditionalInfo/AdditionalInfo";
import Button from "../components/atoms/Button/Button";

const ExportShippingRequest = () => {
  const initialValues = {
    paymentType: "KAUST",
    costCenter_PocketID: "",
    outsideKAUSTFreeText: "",
    shipperName: "",
    shipperPosition: "",
    shipperCompany: "",
    shipperfullAddress: "",
    shipperCountryId: "",
    shipperCountry: "---Select---",
    shipperCity: "",
    shipperPhone: "",
    shipperEmail: "",
    Attachment: "",
    AttachmentInfo: "",
    consigneeName: "",
    consigneePosition: "",
    consigneeCompany: "",
    consigneeFullAddress: "",
    consigneeCountryId: "",
    consigneeCountry: "",
    consigneeCity: "",
    consigneePhone: "",
    consigneeEmail: "",
    exportShippingRequestDetail: [],
    countryOfOriginId: "",
    countryOfOrigin: "---Select---",
    receivedFromSiteService: "",
    scheduledForDispatch: "",
    pod: "",
    awbNumber: "",
    poNumber: "",
    NumberOfPackages: "",
    shipmentType: "---Select---",
    forwarder: "---Select---",
    status: "---Select---",
    newAWB: "",
    newPOD: "",
    additionalRemarks: "",
    isComplete: false,
  };

  const [toggleProduct, setToggleProduct] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState();

  const location = {
    pathname: "/thank-you",
    state: { referenceNumber: referenceNumber },
  };

  const handleLineNumber = (values) => {
    values.map((value, index) => {
      value.LineNo = index + 1;
    });
  };

  const renameProductFiles = (values) => {
    var lineNo = values[values.length - 1].LineNo;
    var thisFile = values[values.length - 1].ProductAttachment[0];
    if (thisFile != null) {
      var thisFileName = thisFile.name;
      var newFile = new File([thisFile], `${lineNo}_${thisFileName}`);

      values[values.length - 1].ProductAttachment = [newFile];
    }
  };

  const handleDeleteProduct = (index, values) => {
    values.splice(index, 1);
  };

  return (
    <>
      <Heading className="pb-5">Export Request Form</Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          costCenter_PocketID: string().when("paymentType", {
            is: (paymentType) => paymentType === "KAUST",
            then: string().required("Cost Center/Pocket ID is required."),
            otherwise: string(),
          }),
          outsideKAUSTFreeText: string().when("paymentType", {
            is: (paymentType) => paymentType === "Others",
            then: string().required("Payment Outside KAUST is required."),
            otherwise: string(),
          }),
          shipperName: string().required("Shipper Name is required."),
          shipperCompany: string().required(
            "Shipper Company or KAUST department is required."
          ),
          shipperfullAddress: string().required("Shipper Address is required."),
          shipperCountry: string()
            .oneOf(
              countryList.map((country) => country.name),
              "Shipper Country is required."
            )
            .required("Shipper Country is required."),
          shipperCity: string().required("Shipper City is required."),
          shipperPhone: string().required("Shipper Phone number is required."),
          shipperEmail: string()
            .email("Shipper email format is Invalid.")
            .required("Shipper Email is required."),
          consigneeName: string().required("Consignee Name is required."),
          consigneeCompany: string().required("Consignee Company is required."),
          consigneeFullAddress: string().required(
            "Consignee Address is required."
          ),
          consigneeCountry: string()
            .oneOf(
              countryList.map((country) => country.name),
              "Shipper Country is required."
            )
            .required("Consignee Country is required."),
          consigneeCity: string().required("Consignee City is required."),
          consigneePhone: string().required(
            "Consignee Phone number is required."
          ),
          consigneeEmail: string()
            .email("Consignee email format is Invalid.")
            .required("Consignee Email is required."),
          exportShippingRequestDetail: array()
            .min(1, "Product detail grids should have atleast 1 record.")
            .required("Product detail grids should have atleast 1 record."),
          countryOfOrigin: string()
            .oneOf(
              countryList.map((country) => country.name),
              "Country of origin is required."
            )
            .required("Country of origin is required."),
          awbNumber: string().when("receivedFromSiteService", {
            is: (receivedFromSiteService) => receivedFromSiteService,
            then: string().required("AWB Number is required."),
            otherwise: string(),
          }),
          additionalRemarks: string().required(
            "Additional Remarks is required."
          ),
        })}
        onSubmit={async (values) => {
          var formData = new FormData();
          const token = await authService.getAccessToken();

          // append all files and prepare for post
          formData.append("exportShippingData", JSON.stringify(values));

          for (var i = 0; i < values.Attachment.length; i++) {
            formData.append("Attachment", values.Attachment[i]);
          }

          for (var i = 0; i < values.exportShippingRequestDetail.length; i++) {
            formData.append(
              "Attachment",
              values.exportShippingRequestDetail[i].ProductAttachment[0]
            );
          }

          const options = {
            method: "POST",
            headers: !token
              ? {}
              : {
                  Authorization: `Bearer ${token}`,
                },
            body: formData,
            mode: "cors",
          };

          const response = await fetch("exportform/saveentry", options);

          const data = await response.json();
          setReferenceNumber(data.referenceNo);
          setFormSubmitted(true);
        }}
      >
        {(props) => (
          <Form>
            <PaymentDetails
              values={props.values}
              setFieldValue={props.setFieldValue}
            />
            <ShipperDetails
              setFieldValue={props.setFieldValue}
              countryList={countryList}
            />
            <ConsigneeDetails
              setFieldValue={props.setFieldValue}
              countryList={countryList}
            />
            <ProductDetails
              values={props.values}
              handleProductDetails={(productDetail) => {
                let productExists = false;

                for (
                  let i = 0;
                  i < props.values.exportShippingRequestDetail.length;
                  i++
                ) {
                  let product = props.values.exportShippingRequestDetail[i];

                  if (product.LineNo === productDetail.LineNo) {
                    props.values.exportShippingRequestDetail.splice(
                      i,
                      1,
                      productDetail
                    );
                    productExists = true;
                    break;
                  }
                }

                if (!productExists) {
                  props.values.exportShippingRequestDetail.push(productDetail);
                }

                handleLineNumber(props.values.exportShippingRequestDetail);
                renameProductFiles(props.values.exportShippingRequestDetail);
                setToggleProduct(!toggleProduct);
              }}
              handleDeleteProduct={(index) => {
                handleDeleteProduct(
                  index,
                  props.values.exportShippingRequestDetail
                );
                handleLineNumber(props.values.exportShippingRequestDetail);
                setToggleProduct(!toggleProduct);
              }}
              name="exportShippingRequestDetail"
            />
            <AdditionalInfo
              values={props.values}
              setFieldValue={props.setFieldValue}
              countryList={countryList}
              shipmentTypesList={shipmentTypesList}
              forwardersList={forwardersList}
              statusList={statusList}
            />
            <div className="text-center">
              <ConditionalWrapper
                condition={isFormSubmitted}
                wrapper={(children) => (
                  <Redirect to={location}>{children} </Redirect>
                )}
              >
                <Button type="submit" btnText="Submit" />
              </ConditionalWrapper>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ExportShippingRequest;
