import React from "react";
import { Row, Col } from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import Heading from "../components/atoms/Heading/Heading";

const SuccessPage = ({ location }) => {
  return (
    <>
      {location.state ? (
        <Row className="justify-content-center">
          <Col md="9" lg="8" xl="6">
            <div className="text-center shadow-v9 p-4 pb-lg-4 pt-lg-2">
              <Heading>Thank you</Heading>
              <p>Your application has been successfully submitted!</p>
              <p>The reference number is: <b>{location.state.referenceNumber}</b> </p>
              <Link to="/" className="btn btn-primary">
                Back Home
              </Link>
            </div>
          </Col>
        </Row>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default SuccessPage;
