import React from "react";
import { Row, Col } from "reactstrap";
import { useField } from "formik";
import "react-nice-dates/build/style.css";
import FormikSelect from "../../../formik/FormikSelect/FormikSelect";
import Required from "../../../atoms/Required/Required";

const AdditionalInfo = ({
  setFieldValue,
  countryList,
  meta,
  touched,
  error,
  ...props
}) => {
  const [field] = useField(props);

  const handleSelectedCountry = (e, field) => {
    let selectedCountryId = "";

    countryList.filter((country) => {
      if (country.name === e.target.value) {
        return (selectedCountryId = country.id);
      }
    });

    setFieldValue("countryOfOrigin", e.target.value, false);
    setFieldValue("countryOfOriginId", selectedCountryId, false);
    field.onChange(e);
  };

  return (
    <fieldset>
      <Row>
        <Col md="6">
          <label>
            Country of origin
            <Required />
            <span className="small">
              (if sending SAMPLES from KAUST, choose Saudi Arabia. Otherwise,
              choose country of manufacture)
            </span>
          </label>
          <FormikSelect
            optionsList={countryList}
            name="countryOfOrigin"
            onChange={(e) => {
              handleSelectedCountry(e, field);
            }}
          />
        </Col>
      </Row>
    </fieldset>
  );
};

export default AdditionalInfo;
