import React from "react";

const Input = ({
  min,
  max,
  type,
  name,
  fieldId,
  maxLength,
  disabled,
  value,
  placeholder,
  className,
  inputPattern,
  onInputChange,
  onInputFocus,
  onInputBlur,
}) => {
  return (
    <input
      min={min}
      max={max}
      type={type}
      name={name}
      id={fieldId ? fieldId : null}
      maxLength={maxLength}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      className={`form-control ${className ? className : ""}`}
      pattern={inputPattern}
      onChange={onInputChange}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
    />
  );
};

export default Input;
