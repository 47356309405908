import React from "react";
import Legend from "../../atoms/Legend/Legend";

const LegendWithUnderline = ({ legend }) => {
  return (
    <>
      <Legend legend={legend} />
      <div className="width-4rem height-4 bg-primary rounded mt-4 marginBottom-40 mx-auto"></div>
    </>
  );
};

export default LegendWithUnderline;
