import React from "react";
import { Field } from "formik";
import Required from "../../atoms/Required/Required";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";

const FormikCheckbox = ({
  isRequired,
  label,
  reversed,
  children,
  ...props
}) => {
  return (
    <Field name={props.name}>
      {({ field, meta }) => (
        <>
          <div className="d-flex align-items-baseline">
            {isRequired && <Required reversed={reversed} />}
            <input
              type="checkbox"
              className="mr-2"
              id={props.id || props.name}
              {...field}
              {...props}
            />
            {label && <label htmlFor={props.id || props.name} className="mb-0">{label}</label>}
          </div>
          {meta.touched && meta.error ? (
            <ErrorMessage errorMessage={meta.error} />
          ) : null}
        </>
      )}
    </Field>
  );
};

export default FormikCheckbox;
