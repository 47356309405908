import React from "react";
import { Row, Col } from "reactstrap";
import { useField } from "formik";
import LegendWithUnderline from "../../../molecules/LegendWithUnderline/LegendWithUnderline";
import FormikInput from "../../../formik/FormikInput/FormikInput";
import FormikTextarea from "../../../formik/FormikTextarea/FormikTextarea";

const ConsigneeDetails = ({ setFieldValue, countryList, ...props }) => {
  const [field] = useField(props);

  return (
    <fieldset className="pb-5">
      <LegendWithUnderline
        legend="Consignee Details"
        className="h2 text-center"
      />
      <FormikInput
        maxLength="50"
        isRequired
        label="Name"
        name="consigneeName"
      />

      <Row>
        <Col md="6">
          <FormikInput
            maxLength="50"
            label="Title / Position"
            name="consigneePosition"
          />
        </Col>        
      </Row>
      <FormikTextarea
        isRequired
        label="Full Address"
        maxLength="500"
        name="consigneeFullAddress"
      />
      <Row>        
        <Col md="6">
          <FormikInput
            label="KAUST Department"            
            maxLength="100"
            name="kAUSTDepartment"
          />
              </Col>
              <Col md="6">
                  <FormikInput
                      label="Building"
                      maxLength="100"
                      name="building"
                  />
              </Col>
      </Row>
      <Row>
        
              <Col md="6">
                  <FormikInput
                      label="Room"
                      maxLength="100"
                      name="room"
                  />
              </Col>
              <Col md="6">
                  <FormikInput
                      maxLength="50"
                      label="Phone #"
                      name="consigneePhone"
                      isRequired
                  />
              </Col>
              <Col md="6">
          <FormikInput
            maxLength="50"
            isRequired
            label="Email"
            name="consigneeEmail"
            type="email"
          />
        </Col>
      </Row>          
    </fieldset>
  );
};

export default ConsigneeDetails;
