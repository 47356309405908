import React from "react";
import { Row, Col } from "reactstrap";
import { useField } from "formik";
import LegendWithUnderline from "../../../molecules/LegendWithUnderline/LegendWithUnderline";
import FormikInput from "../../../formik/FormikInput/FormikInput";
import FormikTextarea from "../../../formik/FormikTextarea/FormikTextarea";
import FormikSelect from "../../../formik/FormikSelect/FormikSelect";
import Input from "../../../atoms/Input/Input";

const ConsigneeDetails = ({ setFieldValue, countryList, ...props }) => {
  const [field] = useField(props);

  const handleSelectedCountry = (e, field) => {
    let selectedCountryId = "";

    countryList.filter((country) => {
      if (country.name === e.target.value) {
        return (selectedCountryId = country.id);
      }
    });

    setFieldValue("consigneeCountry", e.target.value, false);
    setFieldValue("consigneeCountryId", selectedCountryId, false);
    field.onChange(e);
    };

    const handleConsigneeAttachment = (e, field) => {
        var check = Array.from(field.value["Attachment"]);

        if (e.target.files[0] == null) {
            setFieldValue("Attachment", check.filter(el => el.name.startsWith("shipper")), false);
            return;
        }   

        if (check.length == 0) {
            var newFileS = new File([e.target.files[0]], `consignee_${e.target.files[0].name}`);
            setFieldValue("Attachment", [newFileS], false);
        }
        else {
            if (check.some(el => el.name.startsWith("consignee"))) {
                var newFileS = new File([e.target.files[0]], `consignee_${e.target.files[0].name}`);
                if (check.some(el => el.name.startsWith("shipper"))) {
                    var otherFiles = check.filter(el => el.name.startsWith("shipper")); 
                    otherFiles.push(newFileS);
                    
                    setFieldValue("Attachment", otherFiles, false);                    
                }
                else {
                    setFieldValue("Attachment", [newFileS], false);
                }                
            }
            else {
                var newFileC = new File([e.target.files[0]], `consignee_${e.target.files[0].name}`);
                check.push(newFileC);
                setFieldValue("Attachment", check, false);
            }
        }
        field.onChange(e);
    };

  return (
    <fieldset className="pb-5">
      <LegendWithUnderline
        legend="Consignee Details"
        className="h2 text-center"
      />
      <FormikInput
        maxLength="50"
        isRequired
        label="Name"
        name="consigneeName"
      />

      <Row>
        <Col md="6">
          <FormikInput
            maxLength="50"
            label="Title / Position"
            name="consigneePosition"
          />
        </Col>
        <Col>
          <FormikInput
            isRequired
            maxLength="50"
            label="Company"
            name="consigneeCompany"
          />
        </Col>
      </Row>
      <FormikTextarea
        isRequired
        label="Full Address"
        maxLength="500"
        name="consigneeFullAddress"
      />
      <Row>
        <Col md="6">
          <FormikSelect
            optionsList={countryList}
            label="Country"
            name="consigneeCountry"
            isRequired
            onChange={(e) => {
              handleSelectedCountry(e, field);
            }}
          />
        </Col>
        <Col md="6">
          <FormikInput
            label="City"
            isRequired
            maxLength="100"
            name="consigneeCity"
          />
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <FormikInput
            maxLength="50"
            label="Phone #"
            name="consigneePhone"
            isRequired
          />
        </Col>
        <Col>
          <FormikInput
            maxLength="50"
            isRequired
            label="Email"
            name="consigneeEmail"
            type="email"
          />
        </Col>
      </Row>
          <Input type="file" name="consigneeAttachment" className="mb-4" onInputChange={(e) => handleConsigneeAttachment(e, field)} />
    </fieldset>
  );
};

export default ConsigneeDetails;
