import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Field, FieldArray, useField } from "formik";
import { enUS } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import Label from "../../../../molecules/Label/Label";
import FormikTextarea from "../../../../formik/FormikTextarea/FormikTextarea";
import FormikInput from "../../../../formik/FormikInput/FormikInput";
import FormikSelect from "../../../../formik/FormikSelect/FormikSelect";
import ErrorMessage from "../../../../atoms/ErrorMessage/ErrorMessage";

const AddProduct = ({
  values,
  productTypesList,
  conditionList,
  setFieldValue,
  setIsValidForm,
  isFormCleared,
  meta,
  touched,
  error,
  ...props
}) => {
  const [field] = useField(props);
  const [date, setDate] = useState();
  const [fileSize, setFileSize] = useState(0);
  const [validFileSize, setValidFileSize] = useState(true);

  const handleSelectedProductType = (e, field) => {
    let selectedProductTypeId = "";

    productTypesList.filter((type) => {
      if (type.name === e.target.value) {
        return (selectedProductTypeId = type.id);
      }
    });

    setFieldValue("productTypeDescription", e.target.value, false);
    setFieldValue("productTypeId", selectedProductTypeId, false);
    field.onChange(e);
  };

  const handleSelectedCondition = (e, field) => {
    let selectedConditionId = "";

    conditionList.filter((condition) => {
      if (condition.name === e.target.value) {
        return (selectedConditionId = condition.id);
      }
    });

    setFieldValue("prodCondDescription", e.target.value, false);
    setFieldValue("productConditionId", selectedConditionId, false);
    field.onChange(e);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (fileSize > 5000000) {
        setIsValidForm(false);
        setValidFileSize(false);
      } else {
        setIsValidForm(true);
        setValidFileSize(true);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [fileSize]);

  useEffect(() => {
    setFileSize(0);
  }, [isFormCleared]);

  return (
    <>
      <p className="small text-center text-danger">
        Please provide as many details as possible on the specification on the
        item, e.g. make, model #, size, weight, etc.
      </p>
      <Row>
        <Col md="2">
          <Label label="Product Description" isRequired />
        </Col>
        <Col md="10">
          <FormikTextarea name="description" maxLength="300" isRequired />
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <Label label="Product Type" isRequired />
        </Col>
        <Col md="4">
          <FormikSelect
            optionsList={productTypesList}
            name="productTypeDescription"
            onChange={(e) => {
              handleSelectedProductType(e, field);
            }}
          />
        </Col>
        <Col md="2">
          <Label label="Quantity" isRequired />
        </Col>
        <Col md="4">
          <FormikInput type="number" name="quantity" />
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <Label label="Approximate Date of Manufacture" />
        </Col>
        <Col md="4">
          <div className="form-group">
            <Field name="manufacturingDate">
              {({ field }) => (
                <>
                  <DatePicker
                    name="manufacturingDate"
                    date={date}
                    onDateChange={(selectedDate) => {
                      setDate(selectedDate);

                      if (!selectedDate) {
                        setFieldValue("manufacturingDate", "");
                        return;
                      }

                      let month =
                        parseInt(("0" + selectedDate.getMonth()).slice(-2)) + 1;

                      if (month.toString().length == 1) {
                        month = "0" + month;
                      }

                      setFieldValue(
                        "manufacturingDate",
                        month +
                          "/" +
                          ("0" + selectedDate.getDate()).slice(-2) +
                          "/" +
                          selectedDate.getFullYear()
                      );
                    }}
                    format="MM/dd/yyyy"
                    locale={enUS}
                    {...field}
                    {...props}
                  >
                    {({ inputProps }) => (
                      <input className="form-control" {...inputProps} />
                    )}
                  </DatePicker>
                </>
              )}
            </Field>
          </div>
        </Col>
        <Col md="2">
          <Label label="Condition" isRequired />
        </Col>
        <Col md="4">
          <FormikSelect
            optionsList={conditionList}
            name="prodCondDescription"
            onChange={(e) => {
              handleSelectedCondition(e, field);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <Label label="Original Purchased Value" />
        </Col>
        <Col md="4">
          <FormikInput type="number" name="purchaseValue" />
        </Col>
        <Col md="2">
          <Label label="Expected Value from Sale (SAR)" />
        </Col>
        <Col md="4">
          <FormikInput type="number" name="saleValue" />
        </Col>
      </Row>
      <Row>
        <Col md="2" className="mb-4">
          <Label label="Product Picture(s)" isRequired />
        </Col>
        <Col md="4" className="mb-4">
          <FieldArray name="ProductPictures">
            <Field name="ProductPictures">
              {({ meta }) => (
                <div>
                  <p className="small text-danger">
                    Max. 5 files can be attached and the total size shall not be
                    more than 5MB
                  </p>
                  <label
                    htmlFor="product-pictures"
                    className="btn btn-light btn-sm border-light-v1 cursor-pointer"
                  >
                    Choose File
                  </label>
                  <input
                    id="product-pictures"
                    type="file"
                    className="d-none"
                    value=""
                    onChange={(e) => {
                      if (e.target.files[0] && validFileSize) {
                        setFileSize(fileSize + e.target.files[0].size);

                        if (fileSize < 5000000) {
                          setFieldValue(
                            "ProductPictures",
                            values.ProductPictures.concat(e.target.files[0])
                          );
                        }
                      }
                    }}
                  />
                  {values.ProductPictures &&
                    values.ProductPictures.length > 0 &&
                    values.ProductPictures.map((value, index) => (
                      <div key={index}>
                        {value && (
                          <div className="align-items-center d-flex mb-2">
                            {value.name}

                            <button
                              type="button"
                              className="btn btn-primary btn-xs ml-3"
                              style={{ minWidth: 67 }}
                              onClick={() => {
                                setFileSize(fileSize - value.size);
                                values.ProductPictures.splice(index, 1);

                                setFieldValue(
                                  "ProductPictures",
                                  values.ProductPictures
                                );
                              }}
                            >
                              x Remove
                            </button>
                          </div>
                        )}
                      </div>
                    ))}

                  {meta.touched && meta.error ? (
                    <ErrorMessage errorMessage={meta.error} />
                  ) : null}
                </div>
              )}
            </Field>
          </FieldArray>
          {!validFileSize && (
            <ErrorMessage errorMessage="Files are over 5MB." />
          )}
        </Col>
      </Row>
    </>
  );
};

export default AddProduct;
