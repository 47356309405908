import React from "react";

const Footer = () => {
  return (
    <footer className="bg-dark py-4 mt-auto">
      <p className="text-white-0_5 mb-0 text-center">
        © {new Date().getFullYear()} {" "}
        King Abdullah University of Science and Technology. All rights reserved
      </p>
    </footer>
  );
};

export default Footer;
