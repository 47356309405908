import React from "react";
import LegendWithUnderline from "../../../molecules/LegendWithUnderline/LegendWithUnderline";
import ProductGrid from "./ProductGrid/ProductGrid";

const ProductDetails = ({ values, handleProductDetails, handleDeleteProduct, name }) => {
  return (
    <fieldset className="pb-2">
      <LegendWithUnderline
        legend="Product Details"
        className="h2 text-center"
      />

      <ProductGrid
        values={values}
        handleProductDetails={handleProductDetails}
        handleDeleteProduct={handleDeleteProduct}
        name={name}
      />
    </fieldset>
  );
};

export default ProductDetails;
