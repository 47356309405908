import React from "react";
import { Row, Col } from "reactstrap";
import { useField, Field } from "formik";
import LegendWithUnderline from "../../../molecules/LegendWithUnderline/LegendWithUnderline";
import FormikInput from "../../../formik/FormikInput/FormikInput";
import Label from "../../../molecules/Label/Label";
import ErrorMessage from "../../../atoms/ErrorMessage/ErrorMessage";

const UserDetails = ({ values, setFieldValue, departments, ...props }) => {
  const [field, meta] = useField(props);

  const handleSelectedDepartment = (e) => {
    setFieldValue("userDetails.RequesterDepartment", e.target.value, false);
    setFieldValue("userDetails.DepartmentId", "", false);
  };

  return (
    <fieldset className="pb-5">
      <LegendWithUnderline legend="User Details" className="h2 text-center" />
      <FormikInput
        maxLength="50"
        isRequired
        label="Name"
        name="userDetails.RequesterName"
      />
      <Row>
        <Col md="6">
          <FormikInput
            isRequired
            label="KAUST ID"
            type="number"
            name="userDetails.RequesterKAUSTID"
          />
        </Col>
        <Col md="6">
          <Field name="userDetails.RequesterDepartment">
            {({ meta }) => (
              <div className="form-group">
                <Label
                  fieldId="userDetails.RequesterDepartment"
                  label="Department"
                  isRequired
                />

                <select
                  name="userDetails.RequesterDepartment"
                  className="form-control"
                  id="userDetails.RequesterDepartment"
                  onChange={(e) => handleSelectedDepartment(e)}
                >
                  <option value="---Select---">---Select---</option>

                  {departments.map((option, index) => (
                    <option value={option} key={index}>
                      {option}
                    </option>
                  ))}
                </select>
                {meta.touched && meta.error ? (
                  <ErrorMessage errorMessage={meta.error} />
                ) : null}
              </div>
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormikInput
            maxLength="50"
            label="Phone #"
            name="userDetails.RequesterPhone"
            type="text"
            isRequired
          />
        </Col>
        <Col>
          <FormikInput
            maxLength="50"
            label="Email"
            name="userDetails.RequesterEmail"
            type="email"
            isRequired
          />
        </Col>
      </Row>
    </fieldset>
  );
};

export default UserDetails;
