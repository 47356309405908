import React, { useState } from "react";
import { Button } from "reactstrap";
import { Field } from "formik";
import ErrorMessage from "../../../../atoms/ErrorMessage/ErrorMessage";
import ProductModal from "../ProductModal/ProductModal";

const ProductGrid = ({
  values,
  handleProductDetails,
  handleDeleteProduct,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProduct, setNewProduct] = useState({});

  const emptyProduct = {
    LineNo: "",
    Description: "",
    UOMId: "",
    UOM: "",
    Quantity: "",
    ShippingConditionId: "",
    ShippingCondition: "",
    ExpiryDate: "",
    UnitValue: "",
    LineValue: "",
    CreatedDate: new Date(),
    IsChemical: null,
    // temp needed => check if can be deleted
    IsActive: true,
    ExportRequestId: 1,
    CreatedBy: "",
    ModifyBy: "",
    ModifyDate: new Date(),
    RowStatus: "",
    IsRecordFromList: true,
    ProductAttachment: [],
    ProductAttachmentInfo: "",
  };

  const handleToggleModal = (e, product) => {
    if (product) {
      setNewProduct(product);
    } else {
      setNewProduct(emptyProduct);
    }
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        color="primary"
        size="sm"
        className="mb-3"
        onClick={handleToggleModal}
      >
        + Add New Record
      </Button>

      <div className="table-responsive border mb-4">
        <table
          className={`table mb-0 ${
            values.exportShippingRequestDetail.length ? "table-striped" : null
          }`}
        >
          <thead className="bg-primary">
            <tr>
              {values.exportShippingRequestDetail.length ? <td></td> : null}
              <td>Line No</td>
              <td>Description</td>
              <td>UOM</td>
              <td>Qty</td>
              <td>Shipping Condition</td>
              <td>Expiry Date</td>
              <td>Unit Value $USD</td>
              <td>Line Value</td>
            </tr>
          </thead>
          <tbody>
            {values.exportShippingRequestDetail.length ? (
              <>
                {values.exportShippingRequestDetail.map((product, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm mr-2"
                          onClick={(e) => handleToggleModal(e, product)}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() =>
                            handleDeleteProduct(
                              index,
                              values.exportShippingRequestDetail
                            )
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                    <td>{product.LineNo}</td>
                    <td>{product.Description}</td>
                    <td>{product.UOM}</td>
                    <td>{product.Quantity}</td>
                    <td>{product.ShippingCondition}</td>
                    <td>{product.ExpiryDate ? product.ExpiryDate : ""}</td>
                    <td>{product.UnitValue}</td>
                    <td>{product.LineValue}</td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={9}>There are no records to display.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <ProductModal
        isModalOpen={isModalOpen}
        handleToggleModal={handleToggleModal}
        handleProductDetails={handleProductDetails}
        values={newProduct}
      />

      <Field name={props.name}>
        {({ meta }) => (
          <>
            {meta.touched && meta.error ? (
              <ErrorMessage errorMessage={meta.error} />
            ) : null}
          </>
        )}
      </Field>
    </>
  );
};

export default ProductGrid;
