import React, { useState } from "react";
import { Button } from "reactstrap";
import { Field } from "formik";
import ErrorMessage from "../../../../atoms/ErrorMessage/ErrorMessage";
import ProductModal from "../ProductModal/ProductModal";

const ProductGrid = ({
  values,
  handleProductDetails,
  handleDeleteProduct,
  handleMultipleFilesAttachment,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProduct, setNewProduct] = useState({});

  const emptyProduct = {
    lineNo: "",
    description: "",
    productTypeId: "",
    productTypeDescription: "",
    quantity: "",
    manufacturingDate: "",
    productConditionId: "",
    prodCondDescription: "",
    purchaseValue: "",
    saleValue: "",
    ProductPictures: [],
  };

  const handleToggleModal = (e, product) => {
    if (product) {
      setNewProduct(product);
    } else {
      setNewProduct(emptyProduct);
    }
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        color="primary"
        size="sm"
        className="mb-3"
        onClick={handleToggleModal}
      >
        + Add New Record
      </Button>

      <div className="table-responsive border">
        <table
          className={`table mb-0 ${
            values.nonAssetSaleRequestDetail.length ? "table-striped" : null
          }`}
        >
          <thead className="bg-primary">
            <tr>
              {values.nonAssetSaleRequestDetail.length ? <td></td> : null}
              <td>Line #</td>
              <td>Product Description</td>
              <td>Product Type</td>
              <td>Qty</td>
              <td>Approx. Date of manufacture</td>
              <td>Condition</td>
              <td>Original Purchased Value</td>
              <td>Expected Value from Sale (SAR)</td>
            </tr>
          </thead>
          <tbody>
            {values.nonAssetSaleRequestDetail.length ? (
              <>
                {values.nonAssetSaleRequestDetail.map((product, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm mr-2"
                          onClick={(e) => handleToggleModal(e, product)}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() =>
                            handleDeleteProduct(
                              index,
                              values.nonAssetSaleRequestDetail
                            )
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                    <td>{product.lineNo}</td>
                    <td>{product.description}</td>
                    <td>{product.productTypeDescription}</td>
                    <td>{product.quantity}</td>
                    <td>
                      {product.manufacturingDate
                        ? product.manufacturingDate
                        : ""}
                    </td>
                    <td>{product.prodCondDescription}</td>
                    <td>{product.purchaseValue}</td>
                    <td>{product.saleValue}</td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={8}>There are no records to display.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <ProductModal
        isModalOpen={isModalOpen}
        handleToggleModal={handleToggleModal}
        handleProductDetails={handleProductDetails}
        handleMultipleFilesAttachment={handleMultipleFilesAttachment}
        values={newProduct}
      />

      <Field name="nonAssetSaleRequestDetail">
        {({ meta }) => (
          <>
            {meta.touched && meta.error ? (
              <ErrorMessage errorMessage={meta.error} />
            ) : null}
          </>
        )}
      </Field>
    </>
  );
};

export default ProductGrid;
