import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { string, object, array, bool, number } from "yup";
import { Redirect } from "react-router-dom";
import authService from "../components/api-authorization/AuthorizeService";
import Heading from "../components/atoms/Heading/Heading";
import ProductDetails from "../components/organisms/non-asset/ProductDetails/ProductDetails";
import FormikCheckbox from "../components/formik/FormikCheckbox/FormikCheckbox";
import ConditionalWrapper from "../components/atoms/ConditionalWrapper/ConditionalWrapper";
import Button from "../components/atoms/Button/Button";
import UserDetails from "../components/organisms/non-asset/UserDetails/UserDetails";

const NonAssetSale = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [departments, setDepartments] = useState();

  useEffect(() => {
    let isMounted = true;

    fetch("/UserDetails")
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (isMounted) {
          setUserDetails(data);
          setDepartments(data.RequesterDepartment);
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {userDetails && departments && (
        <NonAssetSaleForm userDetails={userDetails} departments={departments} />
      )}
    </>
  );
};

const NonAssetSaleForm = ({ userDetails, departments }) => {
  const initialValues = {
    userDetails: userDetails,
    nonAssetSaleRequestDetail: [],
    Attachment: "",
    isConfirm: false,
  };

  const [toggleProduct, setToggleProduct] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState();

  const location = {
    pathname: "/thank-you",
    state: { referenceNumber: referenceNumber },
  };

  const handleLineNumber = (values) => {
    values.map((value, index) => {
      value.LineNo = index + 1;
    });
  };

  const handleDeleteProduct = (index, values) => {
    values.splice(index, 1);
  };

  const renameProductFiles = (values) => {
    for (let i = 0; i < values.length; i++) {
      let files = [];

      for (let j = 0; j < values[i].ProductPictures.length; j++) {
        var lineNo = values[i].LineNo;
        var thisFile = values[i].ProductPictures[j];
        if (thisFile != null) {
          var thisFileName = thisFile.name;
          var newFile = new File([thisFile], `${lineNo}_${thisFileName}`);
          files.push(newFile);
        }
      }

      values[i].ProductPictures = files;
    }
  };

  return (
    <>
      <Heading className="pb-5">Non-Asset Sale Form</Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          userDetails: object({
            RequesterName: string().required("Name is required."),
            RequesterKAUSTID: number().required("KAUST ID is required"),
            RequesterDepartment: string()
              .oneOf(
                departments.map((department) => department),
                "Department is required."
              )
              .typeError("Department is required."),
            RequesterPhone: string().required("Phone number is required."),
            RequesterEmail: string()
              .email("Email format is Invalid.")
              .required("Email is required."),
          }),
          nonAssetSaleRequestDetail: array()
            .min(1, "Product detail grids should have atleast 1 record.")
            .required("Product detail grids should have atleast 1 record."),
          isConfirm: bool().oneOf(
            [true],
            "Please mark as confirm before submitting Non-Asset Sale form."
          ),
        })}
        onSubmit={async (values) => {
          var formData = new FormData();
          const token = await authService.getAccessToken();

          formData.append("NonAssetData", JSON.stringify(values));

          for (var i = 0; i < values.nonAssetSaleRequestDetail.length; i++) {
            for (
              let j = 0;
              j < values.nonAssetSaleRequestDetail[i].ProductPictures.length;
              j++
            ) {
              formData.append(
                "Attachment",
                values.nonAssetSaleRequestDetail[i].ProductPictures[j]
              );
            }
          }

          const options = {
            method: "POST",
            headers: !token
              ? {}
              : {
                  Authorization: `Bearer ${token}`,
                },
            body: formData,
            mode: "cors",
          };

          const response = await fetch("nonassetsaleform/saveentry", options);

          const data = await response.json();
          setReferenceNumber(data.referenceNo);
          setFormSubmitted(true);
        }}
      >
        {(props) => (
          <Form>
            <UserDetails
              values={props.values}
              setFieldValue={props.setFieldValue}
              departments={departments}
            />
            <ProductDetails
              values={props.values}
              handleProductDetails={(productDetail) => {
                let productExists = false;

                for (
                  let i = 0;
                  i < props.values.nonAssetSaleRequestDetail.length;
                  i++
                ) {
                  let product = props.values.nonAssetSaleRequestDetail[i];

                  if (product.LineNo === productDetail.LineNo) {
                    props.values.nonAssetSaleRequestDetail.splice(
                      i,
                      1,
                      productDetail
                    );
                    productExists = true;
                    break;
                  }
                }

                if (!productExists) {
                  props.values.nonAssetSaleRequestDetail.push(productDetail);
                }

                handleLineNumber(props.values.nonAssetSaleRequestDetail);
                renameProductFiles(props.values.nonAssetSaleRequestDetail);
                setToggleProduct(!toggleProduct);
              }}
              handleDeleteProduct={(index) => {
                handleDeleteProduct(
                  index,
                  props.values.nonAssetSaleRequestDetail
                );
                handleLineNumber(props.values.nonAssetSaleRequestDetail);
                setToggleProduct(!toggleProduct);
              }}
              name="nonAssetSaleRequestDetail"
            />
            <div className="mt-4">
              <FormikCheckbox
                label="I hereby confirm that my Management (M-grade or higher) has approved the sale of these items in accordance with the details given above."
                name="isConfirm"
                isRequired
                reversed
              />
            </div>
            <div className="text-center mt-4">
              <ConditionalWrapper
                condition={isFormSubmitted}
                wrapper={(children) => (
                  <Redirect to={location}>{children} </Redirect>
                )}
              >
                <Button type="submit" btnText="Submit" />
              </ConditionalWrapper>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NonAssetSale;
