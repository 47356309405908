import React, { useState } from "react";
import { Formik, Form } from "formik";
import { string, object, array } from "yup";
import { Redirect } from "react-router-dom";
import countryList from "../mocks/country-list.json";
import authService from "../components/api-authorization/AuthorizeService";
import Heading from "../components/atoms/Heading/Heading";
import PaymentDetails from "../components/organisms/import/PaymentDetails/PaymentDetails";
import ShipperDetails from "../components/organisms/import/ShipperDetails/ShipperDetails";
import ConsigneeDetails from "../components/organisms/import/ConsigneeDetails/ConsigneeDetails";
import ProductDetails from "../components/organisms/import/ProductDetails/ProductDetails";
import AdditionalInfo from "../components/organisms/import/AdditionalInfo/AdditionalInfo";
import ConditionalWrapper from "../components/atoms/ConditionalWrapper/ConditionalWrapper";
import Button from "../components/atoms/Button/Button";

const ImportShippingRequest = () => {
  const initialValues = {
    paymentType: "KAUST",
    costCenter_PocketID: "",
    outsideKAUSTFreeText: "",
    shipperName: "",
    shipperPosition: "",
    shipperCompany: "",
    shipperfullAddress: "",
    shipperCountryId: "",
    shipperCountry: "---Select---",
    shipperCity: "",
    shipperPhone: "",
    shipperEmail: "",
    consigneeName: "",
    consigneePosition: "",
    consigneeFullAddress: "",
    consigneePhone: "",
    consigneeEmail: "",
    kAUSTDepartment: "",
    building: "",
    room: "",
    importShippingRequestDetail: [],
    countryOfOriginId: "",
    countryOfOrigin: "---Select---",
  };

  const [toggleProduct, setToggleProduct] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState();

  const location = {
    pathname: "/thank-you",
    state: { referenceNumber: referenceNumber },
  };

  const handleLineNumber = (values) => {
    values.map((value, index) => {
      value.LineNo = index + 1;
    });
  };

  const handleDeleteProduct = (index, values) => {
    values.splice(index, 1);
  };

  return (
    <>
      <Heading className="pb-5">Import Request Form</Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          costCenter_PocketID: string().when("paymentType", {
            is: (paymentType) => paymentType === "KAUST",
            then: string().required("Cost Center/Pocket ID is required."),
            otherwise: string(),
          }),
          outsideKAUSTFreeText: string().when("paymentType", {
            is: (paymentType) => paymentType === "Others",
            then: string().required("Payment Outside KAUST is required."),
            otherwise: string(),
          }),
          shipperName: string().required("Shipper Name is required."),
          shipperCompany: string().required(
            "Shipper Company or KAUST department is required."
          ),
          shipperfullAddress: string().required("Shipper Address is required."),
          shipperPhone: string().required("Shipper Phone number is required."),
          shipperEmail: string()
            .email("Shipper email format is Invalid.")
            .required("Shipper Email is required."),
          consigneeName: string().required("Consignee Name is required."),
          consigneeFullAddress: string().required(
            "Consignee Address is required."
          ),
          consigneePhone: string().required(
            "Consignee Phone number is required."
          ),
          consigneeEmail: string()
            .email("Consignee email format is Invalid.")
            .required("Consignee Email is required."),
          importShippingRequestDetail: array()
            .min(1, "Product detail grids should have atleast 1 record.")
            .required("Product detail grids should have atleast 1 record."),
          countryOfOrigin: string()
            .oneOf(
              countryList.map((country) => country.name),
              "Country of origin is required."
            )
            .required("Country of origin is required."),
        })}
        onSubmit={async (values) => {
          var formData = new FormData();
          const token = await authService.getAccessToken();

          // append all files and prepare for post
          formData.append("importShippingData", JSON.stringify(values));

          const options = {
            method: "POST",
            headers: !token
              ? {}
              : {
                  Authorization: `Bearer ${token}`,
                },
            body: formData,
            mode: "cors",
          };

          const response = await fetch("importform/saveentry", options);

          const data = await response.json();
          setReferenceNumber(data.referenceNo);
          setFormSubmitted(true);
        }}
      >
        {(props) => (
          <Form>
            <PaymentDetails
              values={props.values}
              setFieldValue={props.setFieldValue}
            />
            <ShipperDetails
              setFieldValue={props.setFieldValue}
              countryList={countryList}
            />
            <ConsigneeDetails />
            <ProductDetails
              values={props.values}
              handleProductDetails={(productDetail) => {
                let productExists = false;

                for (
                  let i = 0;
                  i < props.values.importShippingRequestDetail.length;
                  i++
                ) {
                  let product = props.values.importShippingRequestDetail[i];

                  if (product.LineNo === productDetail.LineNo) {
                    props.values.importShippingRequestDetail.splice(
                      i,
                      1,
                      productDetail
                    );
                    productExists = true;
                    break;
                  }
                }

                if (!productExists) {
                  props.values.importShippingRequestDetail.push(productDetail);
                }

                handleLineNumber(props.values.importShippingRequestDetail);
                setToggleProduct(!toggleProduct);
              }}
              handleDeleteProduct={(index) => {
                handleDeleteProduct(
                  index,
                  props.values.importShippingRequestDetail
                );
                handleLineNumber(props.values.importShippingRequestDetail);
                setToggleProduct(!toggleProduct);
              }}
              name="importShippingRequestDetail"
            />
            <AdditionalInfo
              setFieldValue={props.setFieldValue}
              countryList={countryList}
            />
            <div className="text-center">
              <ConditionalWrapper
                condition={isFormSubmitted}
                wrapper={(children) => (
                  <Redirect to={location}>{children} </Redirect>
                )}
              >
                <Button type="submit" btnText="Submit" />
              </ConditionalWrapper>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ImportShippingRequest;
