import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu";
import Footer from "./Footer";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <>
        <NavMenu />
        <main className="py-5">
          <Container>{this.props.children}</Container>
        </main>
        <Footer />
      </>
    );
  }
}
