import React from "react";
import Required from "../../atoms/Required/Required";

const Label = ({ children, labelClassName, label, isRequired, fieldId }) => {
  return (
    <label className={labelClassName} htmlFor={fieldId}>
      {label}
      {isRequired && (
       <Required />
      )}
      {children}
    </label>
  );
};

export default Label;
