import React from 'react';

const Button = ({ children, type, btnText, variant, disabled }) => {
  return (
    <button
      type={type}
      className={`btn ${variant ? variant : ""}`}
      disabled={disabled ? true : null}
    >
      {children}
      {btnText}
    </button>
  );
};

Button.defaultProps = {
  type: `button`,
  variant: `btn-primary`,
};

export default Button;
