import React from "react";
import { useField, Field } from "formik";
import Label from "../../molecules/Label/Label";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";

const FormikTextarea = ({ label, maxLength, isRequired, ...props }) => {
  const [field, meta] = useField(props);
  
  return (
    <div className="form-group">
      <Field name={props.name}>
        {({ field, meta }) => (
          <>
            {label && (
              <Label
                fieldId={props.id || props.name}
                label={label}
                isRequired={isRequired}
              />
            )}
            <textarea
              id={props.id || props.name}
              maxLength={maxLength}
              className="form-control"
              {...field}
              {...props}
            />
            {meta.touched && meta.error ? (
              <ErrorMessage errorMessage={meta.error} />
            ) : null}
          </>
        )}
      </Field>
    </div>
  );
};

export default FormikTextarea;
