import React from "react";

const Required = ({ reversed }) => {
  return (
    <>
      {reversed ? (
        <div className="text-danger d-inline-block mr-1"> *</div>
      ) : (
        <div className="text-danger d-inline-block ml-2"> *</div>
      )}
    </>
  );
};

export default Required;
