import React from "react";
import { Field } from "formik";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";

const FormikRadio = ({
  label,
  maxLength,
  isRequired,
  reversed,
  space,
  isRadioGroup,
  ...props
}) => {
  return (
    <div className={`form-check ${space ? space : ""}`}>
      <Field name={props.name}>
        {({ field, meta }) => (
          <>
            {reversed ? (
              <>
                <input
                  id={props.id || props.name}
                  maxLength={maxLength}
                  className="form-check-input reversed"
                  {...field}
                  {...props}
                />
                {label && (
                  <label
                    htmlFor={props.id || props.name}
                    className="form-check-label"
                  >
                    {label}
                    {isRequired && (
                      <div className="text-danger d-inline-block pl-1 ml-1">
                        {" "}
                        *
                      </div>
                    )}
                  </label>
                )}
                {!isRadioGroup && meta.touched && meta.error ? (
                  <ErrorMessage errorMessage={meta.error} />
                ) : null}
              </>
            ) : (
              <>
                {label && (
                  <label
                    htmlFor={props.id || props.name}
                    className="form-check-label"
                  >
                    {label}
                    {isRequired && (
                      <div className="text-danger d-inline-block pl-1 ml-1">
                        {" "}
                        *
                      </div>
                    )}
                  </label>
                )}
                <input
                  id={props.id || props.name}
                  maxLength={maxLength}
                  className="form-check-input"
                  {...field}
                  {...props}
                />
                {!isRadioGroup && meta.touched && meta.error ? (
                  <ErrorMessage errorMessage={meta.error} />
                ) : null}
              </>
            )}
          </>
        )}
      </Field>
    </div>
  );
};

export default FormikRadio;
