import React from "react";
import { Row, Col } from "reactstrap";
import { useField } from "formik";
import LegendWithUnderline from "../../../molecules/LegendWithUnderline/LegendWithUnderline";
import FormikInput from "../../../formik/FormikInput/FormikInput";
import FormikTextarea from "../../../formik/FormikTextarea/FormikTextarea";
import FormikSelect from "../../../formik/FormikSelect/FormikSelect";
import Input from "../../../atoms/Input/Input";

const ShipperDetails = ({ setFieldValue, countryList, ...props }) => {
    const [field] = useField(props);
    

  const handleSelectedCountry = (e, field) => {
    let selectedCountryId = "";

    countryList.filter((country) => {
      if (country.name === e.target.value) {
        return (selectedCountryId = country.id);
      }
    });

    setFieldValue("shipperCountry", e.target.value, false);
    setFieldValue("shipperCountryId", selectedCountryId, false);
    field.onChange(e);
  };

    const handleShipperAttachment = (e, field) => {
        var check = Array.from(field.value["Attachment"]);

        if (e.target.files[0] == null) {
            setFieldValue("Attachment", check.filter(el => el.name.startsWith("consignee")), false);
            return;
        }

        if (check.length == 0) {
            var newFileS = new File([e.target.files[0]], `shipper_${e.target.files[0].name}`);
            setFieldValue("Attachment", [newFileS], false);            
        }
        else {            
            if (check.some(el => el.name.startsWith("shipper"))) {
                var newFileC = new File([e.target.files[0]], `shipper_${e.target.files[0].name}`);
                if (check.some(el => el.name.startsWith("consignee"))) {
                    var otherFiles = check.filter(el => el.name.startsWith("consignee"));
                    otherFiles.push(newFileC);

                    setFieldValue("Attachment", otherFiles, false);
                }
                else {
                    setFieldValue("Attachment", [newFileC], false);
                }
            }
            else {
                var newFileC = new File([e.target.files[0]], `shipper_${e.target.files[0].name}`);
                check.push(newFileC);
                setFieldValue("Attachment", check, false);
            }
        }
        field.onChange(e);        
    };

  return (
    <fieldset className="pb-5">
      <LegendWithUnderline
        legend="Shipper Details"
        className="h2 text-center"
      />
      <FormikInput maxLength="50" isRequired label="Name" name="shipperName" />

      <Row>
        <Col md="6">
          <FormikInput
            maxLength="50"
            label="Title / Position"
            name="shipperPosition"
            type="text"
          />
        </Col>
        <Col>
          <FormikInput
            isRequired
            maxLength="50"
            label="Company or KAUST Department"
            name="shipperCompany"
            type="text"
          />
        </Col>
      </Row>
      <FormikTextarea
        isRequired
        label="Full Address"
        maxLength="500"
        name="shipperfullAddress"
      />
      <Row>
        <Col md="6">
          <FormikSelect
            optionsList={countryList}
            label="Country"
            name="shipperCountry"
            isRequired
            onChange={(e) => {
              handleSelectedCountry(e, field);
            }}
          />
        </Col>
        <Col md="6">
          <FormikInput
            label="City"
            isRequired
            maxLength="100"
            name="shipperCity"
            type="text"
          />
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <FormikInput
            maxLength="50"
            label="Phone #"
            name="shipperPhone"
            type="text"
            isRequired
          />
        </Col>
        <Col>
          <FormikInput
            maxLength="50"
            isRequired
            label="Email"
            name="shipperEmail"
            type="email"
          />
        </Col>
      </Row>
      <Input
        type="file"
        name="shipperAttachment"
        className="mb-4"
        onInputChange={(e) => handleShipperAttachment(e, field)}
      />
    </fieldset>
  );
};

export default ShipperDetails;
