import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Field, FieldArray, useField } from "formik";
import { enUS } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import Label from "../../../../molecules/Label/Label";
import Input from "../../../../atoms/Input/Input";
import FormikTextarea from "../../../../formik/FormikTextarea/FormikTextarea";
import FormikInput from "../../../../formik/FormikInput/FormikInput";
import FormikSelect from "../../../../formik/FormikSelect/FormikSelect";
import FormikRadio from "../../../../formik/FormikRadio/FormikRadio";
import ErrorMessage from "../../../../atoms/ErrorMessage/ErrorMessage";

const AddProduct = ({
  values,
  unitsList,
  shippingConditionList,
  setFieldValue,
  meta,
  touched,
  error,
  ...props
}) => {
  const [field] = useField(props);
  const [date, setDate] = useState();

  const handleSelectedUOM = (e, field) => {
    let selectedUOMId = "";

    unitsList.filter((unit) => {
      if (unit.name === e.target.value) {
        return (selectedUOMId = unit.id);
      }
    });

    setFieldValue("UOM", e.target.value, false);
    setFieldValue("UOMId", selectedUOMId, false);
    field.onChange(e);
  };

  const handleSelectedShippingCondition = (e, field) => {
    let selectedShippingConditionId = "";

    shippingConditionList.filter((condition) => {
      if (condition.name === e.target.value) {
        return (selectedShippingConditionId = condition.id);
      }
    });

    setFieldValue("ShippingCondition", e.target.value, false);
    setFieldValue("ShippingConditionId", selectedShippingConditionId, false);
    field.onChange(e);
  };

  const handleProductType = (e) => {
    setFieldValue("IsChemical", stringToBoolean(e.target.value));
  };

  const stringToBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  const handleProductAttachment = (e, field) => {
    field.value["ProductAttachment"] = e.target.files;
    field.onChange(e);
  };

  return (
    <>
      <Row>
        <Col md="2">
          <Label label="Description" isRequired />
        </Col>
        <Col md="10">
          <FormikTextarea name="Description" isRequired />
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <Label label="UOM" isRequired />
        </Col>
        <Col md="4">
          <FormikSelect
            optionsList={unitsList}
            name="UOM"
            onChange={(e) => {
              handleSelectedUOM(e, field);
            }}
          />
        </Col>
        <Col md="2">
          <Label label="Shipping Condition" isRequired />
        </Col>
        <Col md="4">
          <FormikSelect
            optionsList={shippingConditionList}
            name="ShippingCondition"
            onChange={(e) => {
              handleSelectedShippingCondition(e, field);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <Label label="Expiry Date" />
        </Col>
        <Col md="4">
          <div className="form-group">
            <Field name="ExpiryDate">
              {({ field }) => (
                <>
                  <DatePicker
                    name="ExpiryDate"
                    date={date}
                    onDateChange={(selectedDate) => {
                      setDate(selectedDate);

                      if (!selectedDate) {
                        setFieldValue("ExpiryDate", "");
                        return;
                      }

                      let month =
                        parseInt(("0" + selectedDate.getMonth()).slice(-2)) + 1;

                      if (month.toString().length == 1) {
                        month = "0" + month;
                      }

                      setFieldValue(
                        "ExpiryDate",
                        month +
                          "/" +
                          ("0" + selectedDate.getDate()).slice(-2) +
                          "/" +
                          selectedDate.getFullYear()
                      );
                    }}
                    format="MM/dd/yyyy"
                    locale={enUS}
                    {...field}
                    {...props}
                  >
                    {({ inputProps }) => (
                      <input className="form-control" {...inputProps} />
                    )}
                  </DatePicker>
                </>
              )}
            </Field>
          </div>
        </Col>
        <Col md="2">
          <Label label="Quantity" isRequired />
        </Col>
        <Col md="4">
          <Field name="Quantity">
            {({ field }) => (
              <FormikInput
                type="number"
                name="Quantity"
                onChange={(e) => {
                  if (e.target.value && values.UnitValue) {
                    setFieldValue(
                      "LineValue",
                      e.target.value * values.UnitValue
                    );
                  }
                  field.onChange(e);
                }}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <Label label="Unit Value $USD" isRequired />
        </Col>
        <Col md="4">
          <Field name="UnitValue">
            {({ field }) => (
              <FormikInput
                type="number"
                name="UnitValue"
                onChange={(e) => {
                  if (values.Quantity && e.target.value) {
                    setFieldValue(
                      "LineValue",
                      values.Quantity * e.target.value
                    );
                  }
                  field.onChange(e);
                }}
              />
            )}
          </Field>
        </Col>
        <Col md="2">
          <Label label="Line Value" />
        </Col>
        <Col md="4">
          <FormikInput name="LineValue" disabled />
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <Label label="Product is:" isRequired />
        </Col>
        <Col md="4" className="mb-4">
          <div name="IsChemical">
            <div className="d-flex">
              <FormikRadio
                id="chemical"
                label="Chemical"
                type="radio"
                value="true"
                name="IsChemical"
                checked={values.IsChemical === true}
                onChange={handleProductType}
                reversed
                space="mr-4"
                isRadioGroup
              />
              <FormikRadio
                id="non-chemical"
                label="Non-Chemical"
                type="radio"
                value="false"
                name="IsChemical"
                checked={values.IsChemical === false}
                onChange={handleProductType}
                reversed
                isRadioGroup
              />
            </div>
            {touched.IsChemical && error.IsChemical ? (
              <ErrorMessage errorMessage={error.IsChemical} />
            ) : null}
          </div>
        </Col>
        {values.IsChemical && (
          <>
            <Col md="2">
              <Label label="MSDS\SDS" isRequired />
            </Col>
            <Col md="4" className="mb-4">
              <FieldArray name="ProductAttachment">
                <Field name="ProductAttachment">
                  {({ meta }) => (
                    <div>
                      <label
                        htmlFor="product-file"
                        className="btn btn-light btn-sm border-light-v1 cursor-pointer"
                      >
                        Choose File
                      </label>
                      <input
                        id="product-file"
                        type="file"
                        className="d-none"
                        value=""
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            setFieldValue(
                              "ProductAttachment",
                              values.ProductAttachment.concat(e.target.files[0])
                            );
                          }
                        }}
                      />

                      {values.ProductAttachment &&
                        values.ProductAttachment.length > 0 &&
                        values.ProductAttachment.map((value, index) => (
                          <div key={index}>
                            {value && (
                              <div className="align-items-center d-flex mb-2">
                                {value.name}

                                <button
                                  type="button"
                                  className="btn btn-primary btn-xs ml-3"
                                  style={{ minWidth: 67 }}
                                  onClick={() => {
                                    values.ProductAttachment.splice(index, 1);
                                    setFieldValue(
                                      "ProductAttachment",
                                      values.ProductAttachment
                                    );
                                  }}
                                >
                                  x Remove
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                      {meta.touched && meta.error ? (
                        <ErrorMessage errorMessage={meta.error} />
                      ) : null}
                    </div>
                  )}
                </Field>
              </FieldArray>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default AddProduct;
