import React from "react";
import { Row, Col } from "reactstrap";
import { useField } from "formik";
import Label from "../../../molecules/Label/Label";
import FormikInput from "../../../formik/FormikInput/FormikInput";
import FormikTextarea from "../../../formik/FormikTextarea/FormikTextarea";
import FormikRadio from "../../../formik/FormikRadio/FormikRadio";

const PaymentDetails = ({ values, setFieldValue, ...props }) => {
  const [field] = useField(props);

  const handlePaymentType = (e, field) => {
    if (e.target.value === "KAUST") {
      setFieldValue("outsideKAUSTFreeText", "", false);
    } else {
      setFieldValue("costCenter_PocketID", "", false);
    }

    field.onChange(e);
  };

  return (
    <fieldset className="pb-5">
      <Row>
        <Col md="6" className="mb-4 mb-md-0">
          <Label label="Payment details" isRequired />
          <div className="d-flex">
            <FormikRadio
              id="kaust"
              label="KAUST"
              type="radio"
              name="paymentType"
              value="KAUST"
              checked={values.paymentType === "KAUST"}
              onChange={(e) => {
                handlePaymentType(e, field);
              }}
              reversed
              space="mr-4"
            />
            <FormikRadio
              id="others"
              label="Others"
              type="radio"
              value="Others"
              name="paymentType"
              checked={values.paymentType === "Others"}
              onChange={(e) => {
                handlePaymentType(e, field);
              }}
              reversed
            />
          </div>
        </Col>
        <Col md="6">
          {values.paymentType === "KAUST" ? (
            <FormikInput
              id="txtCCPocketId"
              label="Cost Center/Pocket Id"
              maxLength="50"
              name="costCenter_PocketID"
              isRequired
            />
          ) : (
            <FormikTextarea
              id="txtOthers"
              label="Payment Outside KAUST"
              maxLength="500"
              name="outsideKAUSTFreeText"
              isRequired
            />
          )}
        </Col>
      </Row>
    </fieldset>
  );
};

export default PaymentDetails;
